import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// this can be in different file
const ImgWithSVGSupport = ({
  childImageSharp,
  extension,
  publicURL,
  name,
  ...rest
}) => {
  if (!childImageSharp && extension === 'svg') {
    return <img src={publicURL} {...rest} alt={name} />
  }
  return <Img {...childImageSharp} {...rest} />
}

export default ImgWithSVGSupport
// you can even declare fragment
export const fragment = graphql`
  fragment PlaceholderImageWithSVG on File {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
    name
    extension
    publicURL
  }
`
