import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { element } from 'prop-types'
import posed from 'react-pose'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ImgWithSVGSupport from '../components/ImgWithSVGSupport'

const Content = posed.div({
  open: { applyAtStart: { display: 'block' }, opacity: 1 },
  closed: { applyAtEnd: { display: 'none' }, opacity: 0 },
})

const PostLayout = ({
  className,
  image,
  title,
  text,
  category,
  path,
  small,
}) => (
  <div
    className={`${className ? `${className} ` : ''}bg-secondary post-hover${
      small ? '' : ' post-h-100'
    }`}
  >
    <Link to={path} className="position-relative d-block">
      <small className="font-weight-bold bg-warning text-uppercase px-3 py-1 text-white position-absolute bottom-0 z-index-1">
        {category}
      </small>
      {image && (
        <Img
        fluid={image.localFile.childImageSharp.fluid}
          alt={image.title}
          className={`img-fluid d-block mx-auto${
            small ? ' post-small-image' : ' post-large-image'
          }`}
        />
      )}
    </Link>
    <div className={`${small ? ' p-3' : ' p-4'}`}>
      <article className="h5 font-weight-normal m-0">
        <h2
          className={`clearfix${
            small ? ' h6 mb-1' : ' h4 mb-4'
          } pb-2 font-weight-bold text-capitalize`}
        >
          <u>
            <Link
              to={path}
              className="text-white"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </u>
        </h2>
        <p
          className={`${
            small ? 'small' : ''
          } h5 font-weight-normal p-0 m-0 post-line-height`}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </article>
    </div>
  </div>
)

const PostRowLayout = ({ watchId, readId, listenId, posts, index }) => {
  const MAX_LENGTH_330 = 330
  const MAX_LENGTH_85 = 85

  const watchPost = posts.nodes.find(({ wordpress_id, acf }) => {
    const { watch_read_listen_post: post } = acf
    return (
      post[0].__typename === 'WordPressAcf_watch' && wordpress_id === watchId
    )
  })

  const readPost = posts.nodes.find(({ wordpress_id, acf }) => {
    const { watch_read_listen_post: post } = acf
    return post[0].__typename === 'WordPressAcf_read' && wordpress_id === readId
  })
  const listenPost = posts.nodes.find(({ wordpress_id, acf }) => {
    const { watch_read_listen_post: post } = acf
    return (
      post[0].__typename === 'WordPressAcf_listen' && wordpress_id === listenId
    )
  })

  const getWatchPost = post => {
    if(!post) return null;
    const { wordpress_id, title, path, acf } = post
    const { watch_read_listen_post: p } = acf
    return {
      wordpress_id,
      title,
      description: p[0].editor || p[0].description,
      image: p[0].image,
      playlist_id: p[0].playlist_id,
      path,
    }
  }

  const watch = getWatchPost(watchPost)
  const read = getWatchPost(readPost)
  const listen = getWatchPost(listenPost)


  if(watch && !read && !listen){
    return (watch && <div className="col-md-6 mt-4">
              <PostLayout
                className="mt-4 mt-md-0"
                title={watch.title}
                text={`${watch.description.substring(0, MAX_LENGTH_330)}...`}
                image={watch.image}
                path={`/video/${watch.playlist_id}`}
                category="Watch"
              />
            </div>
          )
  }

  if(watch && read && listen){
    return (
      <>
          {index % 2 == 0 && (
           watch && <div className="col-md-7 col-lg-8 mt-4">
              <PostLayout
                className="mt-4 mt-md-0"
                title={watch.title}
                text={`${watch.description.substring(0, MAX_LENGTH_330)}...`}
                image={watch.image}
                path={`/video/${watch.playlist_id}`}
                category="Watch"
              />
              </div>
          )}
  
          {index % 2 == 0 && (
            read && listen && <div className="col-md-5 col-lg-4 mt-4">
              <PostLayout
                className="mt-4 mt-md-0"
                title={read.title}
                text={`${read.description.substring(0, MAX_LENGTH_85)}...`}
                image={read.image}
                path={read.path}
                category="Read"
                small
              />
              <PostLayout
                className="mt-4"
                title={listen.title}
                text={`${listen.description.substring(0, MAX_LENGTH_85)}...`}
                image={listen.image}
                category="Listen"
                path={listen.path}
                small
              />
            </div>
          )}
  
          {index % 2 != 0 && (
           read && listen && <div className="col-md-5 col-lg-4 order-2 order-md-1 mt-4">
              <PostLayout
                className="mt-4 mt-md-0"
                title={read.title}
                text={`${read.description.substring(0, MAX_LENGTH_85)}...`}
                image={read.image}
                category="Read"
                path={read.path}
                small
              />
              <PostLayout
                className="mt-4"
                title={listen.title}
                text={`${listen.description.substring(0, MAX_LENGTH_85)}...`}
                image={listen.image}
                category="Listen"
                path={listen.path}
                small
              />
            </div>
          )}
  
          {index % 2 != 0 && (
            watch && <div className="col-md-7 col-lg-8 order-1 order-md-2 mt-4">
              <PostLayout
                className="mt-4 mt-md-0"
                title={watch.title}
                text={`${watch.description.substring(0, MAX_LENGTH_330)}...`}
                image={watch.image}
                path={`/video/${watch.playlist_id}`}
                category="Watch"
              />
            </div>
          )}
      </>
    )
  }
  

  return <></>
  
}

const HealYourselfTemplate = ({ data }) => {
  const { soulSVG, bodySVG, mindSVG, wordpressPage, allWordpressPost } = data
  const StaticPage = wordpressPage
  const posts = allWordpressPost
  const { title, content, acf } = StaticPage
  const { heal_yourself } = acf

  let mind_repeater = [];
  let body_repeater = [];
  let soul_repeater = [];

  if(heal_yourself && !!heal_yourself.mind_repeater && !!heal_yourself.body_repeater && !!heal_yourself.soul_repeater){
    mind_repeater = heal_yourself.mind_repeater
    body_repeater = heal_yourself.body_repeater
    soul_repeater = heal_yourself.soul_repeater
  }

  const TAB_1 = 1
  const TAB_2 = 2
  const TAB_3 = 3
  const [tabActive, setTabActive] = useState(TAB_1)
  const handleTabClick = num => {
    setTabActive(num)
  }

  return (
    <Layout>
      <SEO title={title} />
      <section className="bg-green text-center text-white pt-5">
        <div className="container">
          <h1 className="display-4 font-weight-bold mb-3">{title}</h1>
          <div className="h5" dangerouslySetInnerHTML={{ __html: content }} />
          <div className="mt-5">
            <p className="h5 text-center font-weight-bold">
              WHAT WOULD YOU LIKE TO LEARN?
            </p>
            <div className="d-flex flex-wrap flex-column flex-sm-row justify-content-center">
              <Link
                to="/empower-yourself/"
                className="btn btn-sm btn-success mt-3"
              >
                Self Empowerment
              </Link>
              <Link
                to="/heal-yourself/"
                className="btn btn-sm btn-success mt-3 mx-0 mx-sm-3 active"
              >
                Self Healing
              </Link>
              <Link
                to="/heal-your-world/"
                className="btn btn-sm btn-success mt-3"
              >
                Heal The World
              </Link>
            </div>
          </div>

          <ul className="nav nav-tabs justify-content-center mt-5" id="myTab">
            <li id-tab="mind" className="nav-tab-item text-center">
              <button
                className={`nav-tab-link nav-link rounded-0 px-lg-5 py-lg-4 mt-auto font-weight-bold${
                  tabActive === TAB_1 ? ' active' : ''
                }`}
                onClick={() => handleTabClick(TAB_1)}
                onKeyDown={() => handleTabClick(TAB_1)}
              >
                <ImgWithSVGSupport
                  {...mindSVG}
                  loading="lazy"
                  className="tab-svg-icon d-block mx-auto"
                />
                MIND
              </button>
            </li>
            <li id-tab="body" className="nav-tab-item text-center">
              <button
                className={`nav-tab-link nav-link rounded-0 px-lg-5 py-lg-4 mt-auto font-weight-bold${
                  tabActive === TAB_2 ? ' active' : ''
                }`}
                onClick={() => handleTabClick(TAB_2)}
                onKeyDown={() => handleTabClick(TAB_2)}
              >
                <ImgWithSVGSupport
                  {...bodySVG}
                  loading="lazy"
                  className="tab-svg-icon d-block mx-auto"
                />
                BODY
              </button>
            </li>
            <li id-tab="soul" className="nav-tab-item text-center">
              <button
                className={`nav-tab-link nav-link rounded-0 px-lg-5 py-lg-4 mt-auto font-weight-bold${
                  tabActive === TAB_3 ? ' active' : ''
                }`}
                onClick={() => handleTabClick(TAB_3)}
                onKeyDown={() => handleTabClick(TAB_3)}
              >
                <ImgWithSVGSupport
                  {...soulSVG}
                  loading="lazy"
                  className="tab-svg-icon d-block mx-auto"
                />
                SOUL
              </button>
            </li>
          </ul>
        </div>
      </section>

      <section className="bg-dark text-white pb-5">
        <div className="container">
          <Content id="mind" pose={tabActive === TAB_1 ? 'open' : 'closed'}>
            <h2 className="pt-5 mb-0 font-weight-bold">HEAL YOUR MIND</h2>
            <div className="row pt-4">
            {mind_repeater.map((groups, index) => {
              const { group } = groups
              const { watch_id, read_id, listen_id } = group
              return (
                <PostRowLayout
                  watchId={watch_id}
                  readId={read_id}
                  listenId={listen_id}
                  posts={posts}
                  index={index}
                  key={index}
                />
              )
            })}
            </div>
          </Content>
          <Content id="body" pose={tabActive === TAB_2 ? 'open' : 'closed'}>
            <h2 className="pt-5 mb-0 font-weight-bold">HEAL YOUR BODY</h2>
            <div className="row pt-4">
            {body_repeater.map((groups, index) => {
              const { group } = groups
              const { watch_id, read_id, listen_id } = group
              return (
                <PostRowLayout
                  watchId={watch_id}
                  readId={read_id}
                  listenId={listen_id}
                  posts={posts}
                  index={index}
                  key={index}
                />
              )
            })}
            </div>
          </Content>
          <Content id="soul" pose={tabActive === TAB_3 ? 'open' : 'closed'}>
            <h2 className="pt-5 mb-0 font-weight-bold">HEAL YOUR SOUL</h2>
            <div className="row pt-4">
            {soul_repeater.map((groups, index) => {
              const { group } = groups
              const { watch_id, read_id, listen_id } = group
              return (
                <PostRowLayout
                  watchId={watch_id}
                  readId={read_id}
                  listenId={listen_id}
                  posts={posts}
                  index={index}
                  key={index}
                />
              )
            })}
            </div>
          </Content>
        </div>
      </section>
    </Layout>
  )
}

export default HealYourselfTemplate
export const pageQuery = graphql`
  query($id: Int!) {
    soulSVG: file(relativePath: { eq: "heal-yourself/soul.svg" }) {
      ...PlaceholderImageWithSVG
    }
    bodySVG: file(relativePath: { eq: "heal-yourself/body.svg" }) {
      ...PlaceholderImageWithSVG
    }
    mindSVG: file(relativePath: { eq: "heal-yourself/mind.svg" }) {
      ...PlaceholderImageWithSVG
    }
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      content
      path
      date(formatString: "MMMM DD, YYYY")
      acf {
        heal_yourself {
          mind_repeater {
            group {
              watch_id: watch_post
              read_id: read_post
              listen_id: listen_post
            }
          }
          body_repeater {
            group {
              watch_id: watch_post
              read_id: read_post
              listen_id: listen_post
            }
          }
          soul_repeater {
            group {
              watch_id: watch_post
              read_id: read_post
              listen_id: listen_post
            }
          }
        }
      }
    }
    allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: "Heal Yourself" } } } }
    ) {
      nodes {
        title
        path
        acf {
          watch_read_listen_post {
            __typename
            ... on WordPressAcf_watch {
              id
              playlist_id
              name
              subject
              description
              image {
                title
                localFile {
                  childImageSharp {
                     fluid(quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            ... on WordPressAcf_read {
              editor
              image {
                title
                localFile {
                  childImageSharp {
                     fluid(quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            ... on WordPressAcf_listen {
              editor
              image {
                title
                localFile {
                  childImageSharp {
                     fluid(quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        wordpress_id
      }
    }
  }
`
